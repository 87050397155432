enum ApplicationType {
    none = 0,
    homeowner = 1,
    contractor = 2,
    plumber = 3,
    other = 4,
}

enum AddressType {
    billing = 1,
    shipping = 2,
}

enum Theme {
    auto = 0,
    light = 1,
    dark = 2,
}

export { ApplicationType, AddressType, Theme };
