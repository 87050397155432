import {
    Box,
    IconButton,
    Stack,
    Typography,
    Checkbox,
    Grid2,
    FormControlLabel,
    FormGroup,
    Button,
} from '@mui/material';
import React, { useState } from 'react';
import { NavigationConfig } from '../../utils/navigation-constants';
import { regex } from '../../constants/regex';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent, TextFieldComponent } from '@watter/shared-components';
import { LoginUi } from './login-style';
import { signIn, signInWithRedirect } from 'aws-amplify/auth';
import { AppleIcon } from '../../components/icons/apple-icon';
import { FacebookIcon } from '../../components/icons/facebook-icon';
import { GoogleIcon } from '../../components/icons/google-icon';
import { LogoIcon } from '../../components/icons/logo-icon';
import { useDispatch } from 'react-redux';
import { openToast } from '../../store/reducers/toastSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { CognitoCallbacks, CognitoErrorCodes } from '../../utils/application-constants';
const VideoGif = require('../../assets/images/WATTER_splash.mp4');

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [errors, setErrors]: any = useState(null);
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { t } = useTranslation();
    React.useEffect(() => {
        const rememberedUser = localStorage.getItem('rememberedUser');
        if (rememberedUser) {
            setEmail(rememberedUser);
            setRememberMe(true);
        }
    }, []);
    const handleEmail = (e: any) => {
        setEmail((e.target.value).trim());
    };
    const handlePassword = (e: any) => {
        setPassword((e.target.value).trim());
    };
    const isValidForm = () => {
        let error: any = {};
        if (!email.trim()) {
            error['email'] = t('email_validation_text');
        }
        if (email && !regex.emailRegExp.test(email)) {
            error['email'] = t('email_invalid_validation_text');
        }
        if (!password.trim()) {
            error['password'] = t('password_validation_text');
        }
        // commenting this, as it should not throw validation for new user with temporary pwd received through email
        // if (password && !regex.passwordRegExp.test(password)) {
        //     error['password'] = t('password_invalid_validation_text');
        // }
        setErrors(error);
        return Object.keys(error).length === 0;
    };
    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isValidForm()) {
            if (rememberMe) {
                localStorage.setItem('rememberedUser', email);
            } else {
                localStorage.removeItem('rememberedUser');
            }
            try {
                dispatch(showLoader());
                const user = await signIn({ username: email.toLowerCase(), password });
                switch (user?.nextStep?.signInStep) {
                    case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE:
                    case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE:
                        navigate(NavigationConfig.LoginOtpScreen, {
                            state: {
                                maskedNumber: user?.nextStep?.codeDeliveryDetails?.destination,
                                username: email.toLowerCase(),
                                password: password,
                                type: CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE
                                    ? CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE
                                    : CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE,
                            },
                        });
                        break;
                    case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED:
                        navigate(NavigationConfig.PasswordSetup, {
                            state: {
                                username: email.toLowerCase(),
                                password: password,
                                type: CognitoCallbacks.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED,
                            },
                        });
                        break;
                    default:
                }
            } catch (err: any) {
                console.log('login API error: ', err);
                switch (err?.name) {
                    case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('invalid_credentials_message'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    case CognitoErrorCodes.USER_NOT_FOUND_EXCEPTION:
                        dispatch(
                            openToast({
                                show: true,
                                message: t('user_not_exists_error'),
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                        break;
                    default:
                        dispatch(
                            openToast({
                                show: true,
                                message: err?.name,
                                severity: 'error',
                                isAlertOpen: true,
                            }),
                        );
                }
            } finally {
                dispatch(hideLoader());
            }
        }
    };

    const socialLogin = (type: any) => {
        try {
            signInWithRedirect({
                provider: type,
            });
        } catch (err: any) {
            console.log(err, 'err in social login');
        }
    };

    return (
        <>
            <LoginUi container spacing={{ sm: 0, lg: 2 }}>
                <Grid2 size={{ xs: 12, lg: 8 }} display={{ xs: 'none', lg: 'flex' }} alignItems={'center'}>
                    <video width="100%" height="100%" muted autoPlay style={{ objectFit: 'cover' }} src={VideoGif} />
                </Grid2>
                <Grid2 size={{ xs: 12, lg: 4 }} className="feildsLabels" minWidth={{ xs: '100%', lg: 'auto' }}>
                    <Box component="form" height={'100%'} onSubmit={handleLogin} noValidate>
                        <Box
                            pt={3}
                            px={5}
                            pb={2}
                            height={'100%'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            onSubmit={handleLogin}
                        >
                            <Box textAlign={'center'} className="logo">
                                <LogoIcon />
                            </Box>
                            <Typography variant="h1" py={2} textAlign={'center'}>
                                {t('login_title')}
                            </Typography>
                            <Stack mb={1.25} className="cstInput">
                                <TextFieldComponent
                                    onChange={handleEmail}
                                    variant="outlined"
                                    disabled={false}
                                    required={true}
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    error={false}
                                    name="email"
                                    value={email}
                                    size={'small'}
                                    isAutoFocus={true}
                                />
                                {errors?.email && (
                                    <Typography variant="subtitle1" color="error">
                                        {errors?.email}
                                    </Typography>
                                )}
                            </Stack>
                            <Stack className="cstInput">
                                <TextFieldComponent
                                    size="small"
                                    variant="outlined"
                                    type="password"
                                    value={password}
                                    name="password"
                                    placeholder="Password"
                                    onChange={handlePassword}
                                    id="password"
                                    maxLength={20}
                                />
                                {errors?.password && (
                                    <Typography variant="subtitle1" color="error">
                                        {errors?.password}
                                    </Typography>
                                )}
                            </Stack>
                            <Stack
                                justifyContent={'space-between'}
                                flexDirection="row"
                                alignItems="center"
                                my={2.25}
                                mt={1}
                            >
                                <FormGroup>
                                    <FormControlLabel
                                        className="cstCheckbox"
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                onChange={e => setRememberMe(e.target.checked)}
                                            />
                                        }
                                        label={t('remember_me_text')}
                                    />
                                </FormGroup>
                                <Box>
                                    <Button
                                        variant="text"
                                        className="forgot-btn"
                                        onClick={() => navigate(NavigationConfig.ForgotPassword)}
                                    >
                                        {t('forgot_password_text')}
                                    </Button>
                                </Box>
                            </Stack>
                            <ButtonComponent
                                size="medium"
                                variant="contained"
                                fullWidth
                                buttonName={t('login_button')}
                                className="cstBtn"
                            />
                            <Stack className="divider" mt={2}>
                                <Typography variant="h5" textAlign={'center'}>
                                    or
                                </Typography>
                            </Stack>
                            <Stack direction={'row'} my={2} spacing={4} justifyContent={'center'} className="mediaSlot">
                                <IconButton aria-label="google" onClick={() => socialLogin('Google')}>
                                    <GoogleIcon />
                                </IconButton>
                                <IconButton aria-label="Apple" onClick={() => socialLogin('Apple')}>
                                    <AppleIcon />
                                </IconButton>
                                <IconButton aria-label="Facebook" onClick={() => socialLogin('Facebook')}>
                                    <FacebookIcon />
                                </IconButton>
                            </Stack>
                        </Box>
                    </Box>
                </Grid2>
            </LoginUi>
        </>
    );
};
