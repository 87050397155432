import { Box, styled } from '@mui/material';
import { colors } from '../theme';
import zIndex from '@mui/material/styles/zIndex';
import { text } from 'stream/consumers';

export const MainWrapper = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.white,
    boxShadow: '0px 0px 30px 0px #0000001A',
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: theme.spacing(3),
    height: 'calc(100dvh - 160px)',
    overflow: 'auto',
    rowGap: theme.spacing(2.5),
    '& form': {
        display: 'grid',
        gridTemplateColumns: '1fr 35%',
        gap: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column-reverse',
            gap: theme.spacing(1),
        },
    },
    '& .details': {
        '& .MuiTypography-subtitle1': {
            fontFamily: 'good_sansregular',
            color: theme.palette.mode === 'dark' ? colors.info : colors.gray,
        },
        '& input,.MuiSelect-select ': {
            padding: theme.spacing(1.0625, 0),
            textOverflow: 'ellipsis',
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root, .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '0 0 1px 0',
                borderRadius: 0,
                borderColor: theme.palette.mode === 'dark' ? colors.lightGray : colors.lightGray,
            },
        },
        '& .MuiInputBase-root.Mui-disabled': {
            '& .MuiInputBase-input': {
                color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
                '-webkit-text-fill-color': 'inherit',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .header': {
            fontFamily: 'good_sansbold',
        },
        '&.profileDetails': {
            '& .MuiFormControl-root': {
                marginBottom: theme.spacing(0),
            },
        },
        '& .cstVerify': {
            position: 'relative',
            '& .MuiButton-root': {
                position: 'absolute',
                right: theme.spacing(0),
                top: theme.spacing(3.5),
                fontSize: theme.spacing(1.5),
                letterSpacing: 0,
                '&.verify': {
                    background: colors.errorRedBg,
                    color: colors.errorTextBtn,
                },
                '&.verified': {
                    background: colors.verifyBg,
                    color: colors.verifyText,
                },
            },
            '&.cstMobileNumber': {
                '& .MuiSelect-select': {
                    textOverflow: 'inherit',
                },
            },
        },
        '& .cstEmail': {
            '& .cstDisableEmail': {
                '& .MuiInputBase-root.Mui-disabled': {
                    '& .MuiInputBase-input': {
                        color: 'gray',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderBottom: `1px solid ${theme.palette.mode === 'dark' ? colors.lightGray : colors.lightGray}`,
                    },
                },
            },
        },
    },

    '& .actionFooter': {
        '& .cstBtn': {
            boxShadow: '0px 0px 2px 0px #00000005 inset, -6px -6px 16px 0px #FFFFFF0A, 6px 6px 16px 0px #00000040',
            background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightBgButton,
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
            fontSize: theme.spacing(1.75),
            padding: theme.spacing(1.5, 2),
            fontFamily: 'good_sansmedium',
            minWidth: theme.spacing(17.5),
            whiteSpace: 'nowrap',
        },

        [theme.breakpoints.down('sm')]: {
            rowGap: theme.spacing(2),
            '& .MuiBox-root': {
                width: '100%',
                '& .cstBtn': {
                    minWidth: '100%',
                },
            },
        },
    },
    '& .profile': {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            '& img': {
                width: theme.spacing(20),
                height: theme.spacing(20),
                borderRadius: '50%',
                boxShadow: '0px 0px 2px 0px #00000005 inset, -6px -6px 16px 0px #FFFFFF0A, 6px 6px 16px 0px #00000040',
            },
        },
    },
    '& .cstEditProfile': {
        '& .MuiSelect-select': {
            paddingLeft: 0,
        },
        '& .MuiSelect-nativeInput': {
            background: 'transparent',
            border: 0,
            fontFamily: 'good_sansregular',
            color: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
        },
    },
    '& .cstSelectProfile': {
        '& .MuiInputLabel-root': {
            left: theme.spacing(-1.75),
            color: colors.placeholder,
            [theme.breakpoints.up('md')]: {
                top: theme.spacing(-2.375),
            },
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: `1px solid ${theme.palette.mode === 'dark' ? colors.lightGray : colors.lightGray} !important`,
        },
        '&.cstState': {
            '& .MuiInputLabel-root': {
                top: theme.spacing(0),
            },
            '& .MuiInputBase-root,input': {
                paddingLeft: theme.spacing(0),
            },
        },
    },
    '& .cstProfileImage': {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
        },
        '& .cstProfileImageStack': {
            width: theme.spacing(37.5),
            position: 'relative',
            '& .ReactCrop': {
                zIndex: 9,
            },
            '& img': {
                borderRadius: theme.spacing(2),
            },
            '& .cstProfileImageEdit': {
                position: 'absolute',
                inset: 0,
                display: 'none',
                alignItems: 'center',
                justifyContent: 'center',
                '& input': {
                    display: 'none',
                },
                '&:hover': {
                    backdropFilter: ' brightness(0.4)',
                },
            },
            '&:hover': {
                '& .cstProfileImageEdit': {
                    display: 'flex',
                    cursor: 'pointer',
                },
            },
        },
    },
}));
