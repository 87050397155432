import React from 'react';
import { Box, Drawer, IconButton, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { LogoIcon } from '../icons/logo-icon';
import { SideMenuStyles } from './sidemenu-styles';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import { DetailsIcon } from '../icons/details-icon';
import { UserSettingIcon } from '../icons/usersetting-icon';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { NavigationConfig } from '../../utils/navigation-constants';
import { useDispatch } from 'react-redux';
import { revertAll } from '../../store/actions';
import { CommonModal } from '../modal/modal';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
interface IProps {
    menuOpen: any;
    menuClose: any;
}
export const SideMenu: React.FC<IProps> = ({ menuOpen, menuClose }) => {
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [activeMenu, setActiveMenu] = React.useState<string>(NavigationConfig.Home);
    let apolloClient = useApolloClient();
    const handleLogout = () => {
        dispatch(showLoader());
        signOut()
            .then((res: any) => {
                console.log(res, '==> res in signout');
                dispatch(revertAll());
                localStorage.removeItem('authToken'); 
                sessionStorage.clear();   
                apolloClient.clearStore();
                navigate(NavigationConfig.Login);
            })
            .catch((err: any) => {
                console.log('err SignOut: ', err);
            })
            .finally(() => {
                dispatch(hideLoader());
            });
    };
    const pathNavigateClick = (value: any) => {
        navigate(value);
        setActiveMenu(value);
    };
    const MenuItems = [
        {
            pathValue: NavigationConfig.Home,
            title: t('dashboard'),
            icon: <DashboardOutlinedIcon />,
        },
        {
            pathValue: NavigationConfig.ProfileSetting,
            title: t('profile_settings'),
            icon: <PermIdentityOutlinedIcon />,
        },
        {
            pathValue: NavigationConfig.HeaterDetails,
            title: t('heater_details'),
            icon: <DetailsIcon />,
        },
        {
            pathValue: NavigationConfig.NotificationSetting,
            title: t('notification_settings'),
            icon: <UserSettingIcon />,
        },
    ];
    const matches = useMediaQuery(theme => theme.breakpoints.down('lg'));
    const sidemenuContent = () => {
        return (
            <SideMenuStyles height={'100%'}>
                <Box>
                    <Box className="logo" textAlign={'center'}>
                        <LogoIcon />
                    </Box>
                    <Stack className="listItems" height={'100%'} alignItems={{ xs: 'flex-start', lg: 'center' }}>
                        {MenuItems.map(items => (
                            <Tooltip title={items.title} arrow placement="right">
                                <Box
                                    display={{ xs: 'flex', lg: 'block' }}
                                    mb={{ xs: 2, lg: 0 }}
                                    alignItems={'center'}
                                    columnGap={1}
                                    onClick={() => pathNavigateClick(items.pathValue)}
                                >
                                    <IconButton
                                        className={activeMenu === items.pathValue ? 'active' : ''}
                                        sx={{ display: 'block' }}
                                    >
                                        {items.icon}
                                    </IconButton>
                                    {matches && (
                                        <Typography variant="subtitle1" fontFamily="good_sansmedium">
                                            {items.title}
                                        </Typography>
                                    )}
                                </Box>
                            </Tooltip>
                        ))}
                    </Stack>
                </Box>
                <Box className="listItems" display={'flex'} justifyContent={'center'}>
                    <Box
                        display={{ xs: 'flex', lg: 'block' }}
                        mb={{ xs: 2, lg: 0 }}
                        alignItems={'center'}
                        columnGap={1}
                        onClick={() => setModalOpen(true)}
                    >
                        <IconButton>
                            <LogoutIcon />
                        </IconButton>
                        {matches && (
                            <Typography variant="subtitle1" fontFamily="good_sansmedium">
                                {t('logout')}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <CommonModal
                    modalOpen={modalOpen}
                    header={t('logout_text')}
                    cancelBtnName={t('cancel')}
                    AcceptBtnName={t('yes')}
                    closePopup={() => setModalOpen(false)}
                    acceptBtn={handleLogout}
                />
            </SideMenuStyles>
        );
    };
    return (
        <>
            {matches ? (
                <Drawer open={menuOpen} onClose={menuClose}>
                    {sidemenuContent()}
                </Drawer>
            ) : (
                <>{sidemenuContent()}</>
            )}
        </>
    );
};
