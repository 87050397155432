import {
    Box,
    Stack,
    Typography,
    FormHelperText,
    DialogContentText,
    DialogContent,
    Button,
    DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { INavigationConfig, NavigationConfig } from '../../utils/navigation-constants';
import { AppDispatch } from '../../store';
import { confirmSignIn, updatePassword } from 'aws-amplify/auth';
import { ButtonComponent, TextFieldComponent } from '@watter/shared-components';
import { colors, theme } from '../../theme';
import { Formik, Form, ErrorMessage } from 'formik';
import { openToast } from '../../store/reducers/toastSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { CognitoCallbacks, CognitoErrorCodes, PasswordColorCodeStrength } from '../../utils/application-constants';
import { LoginUi } from '../login/login-style';
import { passwordSetupValidationSchema } from '../login/password-setup-validationSchema';
import { ModalUI } from '../../components/modal/modal-styles';
import { useState } from 'react';
import { changePasswordValidationSchema } from './change-password-validationSchema';

export const ProfileSetPassword = ({ open, closePopup }: { open: boolean; closePopup: () => void }) => {
    const navConfig: INavigationConfig = NavigationConfig;
    let dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    const { t } = useTranslation();
    const handleClose = () => {
        setClosePopup(false);
    };
    const [closePopUp, setClosePopup] = useState(false);

    // Handle form submission
    const handleSubmit = async (values: any) => {
        dispatch(showLoader()); // Show loader while updating password
        try {
            await updatePassword({
                oldPassword: values.currentPassword,
                newPassword: values.newPassword,
            });
            dispatch(
                openToast({
                    show: true,
                    message: t('password_updated_successfully'),
                    severity: 'success',
                    isAlertOpen: true,
                }),
            );
            closePopup(); // Close the popup after successful update
        } catch (error: any) {
            console.error('Error updating password:', error);
            dispatch(
                openToast({
                    show: true,
                    message: t('password_update_failed'),
                    severity: 'error',
                    isAlertOpen: true,
                }),
            );
        } finally {
            dispatch(hideLoader()); // Hide loader after the operation
        }
    };

    const getColorPallet = (newPassword: string) => {
        const colors = {
            red: '#EF4444',
            orange: '#F59E0B',
            green: '#22C55E',
            grey: '#808080',
        };

        const REGEX = {
            newPassword: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W]).{8,}$/, // Password strength regex
        };

        let colorArray = [colors.grey, colors.grey, colors.grey, colors.grey];
        let strength = 0;

        strength += /[A-Z]+/.test(newPassword) ? 1 : 0; // Uppercase
        strength += /[a-z]+/.test(newPassword) ? 1 : 0; // Lowercase
        strength += /[0-9]+/.test(newPassword) ? 1 : 0; // Numbers
        strength += /[\W]+/.test(newPassword) ? 1 : 0; // Special characters

        if (newPassword.length < 8 && strength > 2) {
            strength -= 1;
        }

        if (newPassword.length >= 8 && REGEX.newPassword.test(newPassword)) {
            strength = 4;
        }

        switch (strength) {
            case PasswordColorCodeStrength.LOWER_STRENGTH:
                colorArray = [colors.red, colors.grey, colors.grey, colors.grey];
                break;
            case PasswordColorCodeStrength.MINIMUM_STRENGTH:
                colorArray = [colors.orange, colors.orange, colors.grey, colors.grey];
                break;
            case PasswordColorCodeStrength.MEDIUM_STRENGTH:
                colorArray = [colors.green, colors.green, colors.green, colors.grey];
                break;
            case PasswordColorCodeStrength.HIGH_STRENGTH:
                colorArray = [colors.green, colors.green, colors.green, colors.green];
                break;
            default:
                break;
        }

        return colorArray;
    };

    return (
        <ModalUI
            open={open}
            onClose={closePopup}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={'changePassword'}
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <LoginUi height={'auto !important'} container spacing={{ sm: 0, lg: 2 }}>
                        <Box className="feildsLabels" width={'100%'} height={'auto !important'}>
                            <Box height={'100%'}>
                                <Typography variant="h5" mb={1.5} className="header">
                                    {t('change_password')}
                                </Typography>
                                <Box>
                                    <Formik
                                        initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
                                        validationSchema={changePasswordValidationSchema(t)}
                                        onSubmit={handleSubmit}
                                    >
                                        {({ values, setFieldValue }: any) => (
                                            <Form>
                                                <Stack>
                                                    <Typography variant="subtitle1" py={0.5} color={'info.100'}>
                                                        {t('current_password')}  <Box component={'span'} color={colors.astric}>*</Box>
                                                    </Typography>
                                                    <TextFieldComponent
                                                        name="currentPassword"
                                                        type="text"
                                                        onChange={(e: any) =>
                                                            setFieldValue('currentPassword', e.target.value)
                                                        }
                                                    />
                                                    <FormHelperText error={true}>
                                                        <ErrorMessage name="currentPassword" />
                                                    </FormHelperText>
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="subtitle1" pb={0.5} color={'info.100'}>
                                                        {t('new_password_placeholder')}  <Box component={'span'} color={colors.astric}>*</Box>
                                                    </Typography>
                                                    <TextFieldComponent
                                                        name="newPassword"
                                                        type="text"
                                                        onChange={(e: any) =>
                                                            setFieldValue('newPassword', e.target.value)
                                                        }
                                                    />

                                                    <FormHelperText error={true}>
                                                        <ErrorMessage name="newPassword" />
                                                    </FormHelperText>
                                                </Stack>
                                                <Stack>
                                                    <Typography variant="subtitle1" pb={0.5} color={'info.100'}>
                                                        {t('confirm_new_password_placeholder')}  <Box component={'span'} color={colors.astric}>*</Box>
                                                    </Typography>
                                                    <TextFieldComponent
                                                        name="confirmNewPassword"
                                                        type="text"
                                                        onChange={(e: any) =>
                                                            setFieldValue('confirmNewPassword', e.target.value)
                                                        }
                                                    />
                                                    <FormHelperText error={true}>
                                                        <ErrorMessage name="confirmNewPassword" />
                                                    </FormHelperText>
                                                </Stack>
                                                <Stack direction={'row'} spacing={1.5} my={1.5}>
                                                    {getColorPallet(values.newPassword).map((color, index) => (
                                                        <Box
                                                            key={index}
                                                            height={theme.spacing(0.5)}
                                                            width="25%"
                                                            bgcolor={color}
                                                            borderRadius={theme.spacing(1.25)}
                                                        />
                                                    ))}
                                                </Stack>
                                                <Stack pb={1.5}>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_required_text')}
                                                    </Typography>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_include_text1')}
                                                    </Typography>
                                                    <Box>
                                                        <ul>
                                                            <li>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    lineHeight="1.35"
                                                                    color="info"
                                                                >
                                                                    {t('new_password_include_text2')}
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    lineHeight="1.35"
                                                                    color="info"
                                                                >
                                                                    {t('new_password_include_text3')}
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    lineHeight="1.35"
                                                                    color="info"
                                                                >
                                                                    {t('new_password_include_text4')}
                                                                </Typography>
                                                            </li>
                                                            <li>
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    lineHeight="1.35"
                                                                    color="info"
                                                                >
                                                                    {t('new_password_include_text5')}
                                                                </Typography>
                                                            </li>
                                                        </ul>
                                                    </Box>
                                                </Stack>
                                                <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}
                                                    width={'100%'}
                                                    gap={{ xs: 1, sm: 4 }}
                                                    pb={1}
                                                    sx={{
                                                        '& .MuiBox-root ': {
                                                            width: '100%',
                                                        },
                                                    }}
                                                >
                                                    <ButtonComponent
                                                        size="medium"
                                                        variant="contained"
                                                        fullWidth
                                                        buttonName={'cancel'}
                                                        className="cstBtn"
                                                        onClick={closePopup}
                                                    />
                                                    <ButtonComponent
                                                        size="medium"
                                                        variant="contained"
                                                        fullWidth
                                                        buttonName={'update password'}
                                                        className="cstBtn"
                                                        type="submit"
                                                    />
                                                </Stack>
                                            </Form>
                                        )}
                                    </Formik>
                                </Box>
                            </Box>
                        </Box>
                    </LoginUi>
                </DialogContentText>
            </DialogContent>
        </ModalUI>
    );
};

export default ProfileSetPassword;
