import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from '../store/index';
import { nanoid } from 'nanoid';

// GraphQL API endpoint
const httpLink = createHttpLink({
    uri: 'https://dev.api.wattertechnologies.com/graphql',
});

//  Authorization token to the headers
const authLink = setContext((_, { headers }) => {
    const token = store.getState().app?.session?.token;
    const traceId = nanoid();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            'x-trace-id' : `WW-${traceId}`
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});