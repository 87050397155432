import { gql } from '@apollo/client';

export const GET_USERS = gql`
    query getUser {
        getUser {
            application_type
            cognito_id
            email
            first_name
            id
            last_logged_at
            last_name
            phone_number
            profile_image
            status
            user_addresses {
                address_type
                city
                country
                id
                state
                street
                user_id
                zip_code
            }
            username
        }
    }
`;

export const GET_USER_SYSTEM_SETTINGS = gql`
    query getUserSettings {
        getUserSettings {
            theme
            user_id
            id
        }
    }
`;

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation UpdateSettings($input: UpdateSettings!) {
    updateSettings(input: $input) {
      allow_led_light
      allow_notifications
      allow_sound
      allow_vibration
      createdAt
      floating_notifications
      id
      lock_screen_notifications
      show_notifications
      theme
      user_id
    }
  }
`;