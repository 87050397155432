import { Stack, styled } from '@mui/material';
import { colors } from '../../theme';
export const SideMenuStyles = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(1),
    width: theme.spacing(9.35),
    [theme.breakpoints.down('lg')]: {
        width: theme.spacing(25),
        background: theme.palette.mode === 'dark' && colors.darkBgButton,
    },
    justifyContent: 'space-between',
    '& .logo': {
        '& svg': {
            width: theme.spacing(5.625),
            fill: theme.palette.mode === 'dark' ? colors.white : colors.blackColor,
        },
    },
    '& .listItems': {
        '& .MuiIconButton-root': {
            display: 'flex',
            width: theme.spacing(4.75),
            height: theme.spacing(4.75),
            background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray,
            border: `2px solid ${theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray}`,
            marginBottom: theme.spacing(1),
            position: 'relative',
            '& svg': {
                width: theme.spacing(2.5),
                '& path': {
                    fill: theme.palette.mode === 'dark' ? colors.info : colors.gray,
                },
            },
            '&.active': {
                '&::after': {
                    content: "''",
                    background:
                        'linear-gradient(90deg, #2FABE1 0%, #59BDE7 5.13%, #89D1ED 11.85%, #A8DEF1 16.98%, #B3E3F3 19.87%, #FDCC3F 40.5%, #FF8524 61.36%, #E03C23 80.8%, #441212 100%)',
                    width: theme.spacing(5.25),
                    height: theme.spacing(5.25),
                    position: 'absolute',
                    borderRadius: '50%',
                    zIndex: -1,
                },
            },
        },
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'flex-start',
            '& .MuiIconButton-root': {
                marginBottom: theme.spacing(0),
            },
        },
    },
}));
