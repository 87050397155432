import moment from 'moment';
import * as Yup from 'yup';

export const ProfileSettingValidationSchema = (mdoValidations: any) =>
    Yup.object().shape({
        firstName: Yup.string()
            .min(3, 'First Name should be atleast 3 characters')
            .max(30, 'First Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('First Name required'),
        lastName: Yup.string()
            .min(1, 'Last Name should be atleast 1 character')
            .max(30, 'Last Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('Last Name required'),
        email: Yup.string()
            .email('Please enter a vaild email')
            .trim('Starting spaces are not allowed')
            .required('Email required'),
        // mobile_number: Yup.string()
        //     .min(1, 'Phone number should be atleast 1 character')
        //     .max(30, 'Phone number should be a max of 12 characters')
        //     .trim('Starting spaces are not allowed')
        //     .required('Phone Number required'),
        street: Yup.string()
            .min(3, 'Street should be atleast 3 characters')
            .max(30, 'Street should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('Street Name required'),
        city: Yup.string()
            .min(3, 'city Name should be atleast 3 characters')
            .max(30, 'Ficityrst Name should be a max of 30 characters')
            .trim('Starting spaces are not allowed')
            .required('City Name required'),
        // state: Yup.string()
        //     .min(3, 'state Name should be atleast 3 characters')
        //     .max(30, 'state Name should be a max of 30 characters')
        //     .trim('Starting spaces are not allowed')
        //     .required('state Name required'),
        // country: Yup.string()
        //     .min(3, 'country Name should be atleast 3 characters')
        //     .max(30, 'country Name should be a max of 30 characters')
        //     .trim('Starting spaces are not allowed')
        //     .required('country Name required'),
        zipCode: Yup.string()
            .matches(/^([1-9][0-9]{5}|\d{5}(-\d{4})?)$/, 'Please enter a valid zip code for India or the United States')
            .required('Zip Code is required'),
    });
