import { Stack, styled } from '@mui/material';
import { colors } from '../../theme';
export const HeaderStyles = styled(Stack)(({ theme }) => ({
    '& .MuiToggleButtonGroup-root': {
        borderRadius: theme.spacing(12.5),
        overflow: 'hidden',
        alignItems: 'center',
        background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray,
        padding: theme.spacing(1),
        columnGap: theme.spacing(1),
        '& .MuiButtonBase-root': {
            borderRadius: '50%',
            padding: theme.spacing(0.5),
            width: theme.spacing(3.25),
            height: theme.spacing(3.25),
            border: 'none',
            '&.Mui-selected': {
                background: theme.palette.mode === 'dark' ? colors.blackColor : colors.white,
            },
            '& svg': {
                width: theme.spacing(2.25),
            },
        },
    },
    '& .MuiIconButton-root': {
        background: theme.palette.mode === 'dark' ? colors.darkBgButton : colors.lightGray,
        color: theme.palette.mode === 'dark' ? colors.info : colors.gray,
        width: theme.spacing(5),
        '& svg': {
            width: theme.spacing(2.25),
        },
    },
    '& .MuiAvatar-root': {
        fontSize: theme.spacing(1.75),
    },
}));
