import { Box, Grid2, IconButton, Stack, Typography, FormHelperText } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { INavigationConfig, NavigationConfig } from '../../utils/navigation-constants';
import { AppDispatch } from '../../store';
import { confirmSignIn } from 'aws-amplify/auth';
import { ButtonComponent, TextFieldComponent } from '@watter/shared-components';
import { LoginUi } from './login-style';
import { theme } from '../../theme';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Formik, Form, ErrorMessage } from 'formik';
import { passwordSetupValidationSchema } from './password-setup-validationSchema';
import { PasswordIcon } from '../../components/icons/password-icon';
import { openToast } from '../../store/reducers/toastSlice';
import { hideLoader, showLoader } from '../../store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { CognitoCallbacks, CognitoErrorCodes, PasswordColorCodeStrength } from '../../utils/application-constants';
export const PasswordSetup = () => {
    const navConfig: INavigationConfig = NavigationConfig;
    let dispatch = useDispatch<AppDispatch>();
    let navigate = useNavigate();
    const { t } = useTranslation();
    // const { type, username } = useLocation().state;
    const location = useLocation();
    const type = location.state?.type;
    const username = location.state?.username;

    const getColorPallet = (password: string) => {
        const colors = {
            red: '#EF4444',
            orange: '#F59E0B',
            green: '#22C55E',
            grey: '#808080',
        };

        const REGEX = {
            password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W]).{8,}$/, // Password strength regex
        };

        let colorArray = [colors.grey, colors.grey, colors.grey, colors.grey];
        let strength = 0;

        strength += /[A-Z]+/.test(password) ? 1 : 0; // Uppercase
        strength += /[a-z]+/.test(password) ? 1 : 0; // Lowercase
        strength += /[0-9]+/.test(password) ? 1 : 0; // Numbers
        strength += /[\W]+/.test(password) ? 1 : 0; // Special characters

        if (password.length < 8 && strength > 2) {
            strength -= 1;
        }

        if (password.length >= 8 && REGEX.password.test(password)) {
            strength = 4;
        }

        switch (strength) {
            case PasswordColorCodeStrength.LOWER_STRENGTH:
                colorArray = [colors.red, colors.grey, colors.grey, colors.grey];
                break;
            case PasswordColorCodeStrength.MINIMUM_STRENGTH:
                colorArray = [colors.orange, colors.orange, colors.grey, colors.grey];
                break;
            case PasswordColorCodeStrength.MEDIUM_STRENGTH:
                colorArray = [colors.green, colors.green, colors.green, colors.grey];
                break;
            case PasswordColorCodeStrength.HIGH_STRENGTH:
                colorArray = [colors.green, colors.green, colors.green, colors.green];
                break;
            default:
                break;
        }

        return colorArray;
    };

    // Handle form submission
    const handleSetPassword = async (values: { password: string; confirmPassword: string }) => {
        try {
            dispatch(showLoader());
            const res: any = await confirmSignIn({
                challengeResponse: values?.password,
            });
            switch (res?.nextStep?.signInStep) {
                case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE:
                case CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE:
                    navigate(navConfig.LoginOtpScreen, {
                        state: {
                            maskedNumber: res?.nextStep?.codeDeliveryDetails?.destination,
                            type: CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE
                                ? CognitoCallbacks.CONFIRM_SIGN_IN_WITH_SMS_CODE
                                : CognitoCallbacks.CONFIRM_SIGN_IN_WITH_EMAIL_CODE,
                        },
                    });
                    break;
            }
        } catch (err: any) {
            switch (err?.name) {
                case CognitoErrorCodes.NOT_AUTHORIZED_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('otp_expired_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                case CognitoErrorCodes.SIGN_IN_EXCEPTION:
                    dispatch(
                        openToast({
                            show: true,
                            message: t('sign_in_exception_message'),
                            severity: 'error',
                            isAlertOpen: true,
                        }),
                    );
                    break;
                default:
                    dispatch(openToast({ show: true, message: err?.name, severity: 'error', isAlertOpen: true }));
            }
        } finally {
            dispatch(hideLoader());
        }
    };

    // handle next
    const handleResetPassword = (values: { password: string; confirmPassword: string }) => {
        navigate(navConfig.LoginOtpScreen, {
            state: {
                username: username,
                password: values.password,
                confirmPassword: values.confirmPassword,
                type: CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE,
            },
        });
    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <LoginUi container spacing={{ sm: 0, lg: 2 }}>
            <Grid2 className="imgContainer" size={{ sm: 12, lg: 8 }} display={'flex'} alignItems={'center'}>
                <Box />
            </Grid2>
            <Grid2 size={{ sm: 12, lg: 4 }} className="feildsLabels" position={'relative'}>
                <IconButton className="backBtn" onClick={handleBack}>
                    <KeyboardBackspaceIcon />
                </IconButton>
                <Box height={'100%'}>
                    <Box
                        pt={3}
                        px={5}
                        pb={2}
                        height={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <Box textAlign={'center'} className="logo passwordLogo ">
                            <PasswordIcon />
                        </Box>
                        <Typography variant="h4" pt={1.5} textAlign={'center'}>
                            {t('password_setup_title')}
                        </Typography>
                        <Typography variant="subtitle1" lineHeight="1.35" py={1.5} textAlign={'center'} color="info">
                            {t('password_valid_text')}
                        </Typography>
                        <Formik
                            initialValues={{ password: '', confirmPassword: '' }}
                            validationSchema={passwordSetupValidationSchema(t)}
                            onSubmit={values => {
                                if (type === CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE) {
                                    handleResetPassword(values);
                                } else {
                                    handleSetPassword(values);
                                }
                            }}
                        >
                            {({ values, setFieldValue }: any) => (
                                <Form>
                                    <Stack mb={1.25} className="cstInput">
                                        <TextFieldComponent
                                            name="password"
                                            type="password"
                                            placeholder={t('new_password_placeholder')}
                                            onChange={(e: any) => setFieldValue('password', e.target.value)}
                                            maxLength={20}
                                        />

                                        <FormHelperText error={true}>
                                            <ErrorMessage name="password" />
                                        </FormHelperText>
                                    </Stack>
                                    <Stack mb={1.25} className="cstInput">
                                        <TextFieldComponent
                                            name="confirmPassword"
                                            type="password"
                                            placeholder={t('confirm_password_placeholder')}
                                            onChange={(e: any) => setFieldValue('confirmPassword', e.target.value)}
                                            maxLength={20}
                                        />
                                        <FormHelperText error={true}>
                                            <ErrorMessage name="confirmPassword" />
                                        </FormHelperText>
                                    </Stack>
                                    <Stack direction={'row'} spacing={1.5} mb={2.5}>
                                        {getColorPallet(values.password).map((color, index) => (
                                            <Box
                                                key={index}
                                                height={theme.spacing(0.5)}
                                                width="25%"
                                                bgcolor={color}
                                                borderRadius={theme.spacing(1.25)}
                                            />
                                        ))}
                                    </Stack>
                                    <Stack pb={1.5}>
                                        <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                            {t('new_password_required_text')}
                                        </Typography>
                                        <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                            {t('new_password_include_text1')}
                                        </Typography>
                                        <Box>
                                            <ul>
                                                <li>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_include_text2')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_include_text3')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_include_text4')}
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography variant="subtitle1" lineHeight="1.35" color="info">
                                                        {t('new_password_include_text5')}
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </Box>
                                    </Stack>
                                    <ButtonComponent
                                        size="medium"
                                        variant="contained"
                                        fullWidth
                                        buttonName={
                                            type === CognitoCallbacks.CONFIRM_RESET_PASSWORD_WITH_CODE
                                                ? t('next')
                                                : t('submit')
                                        }
                                        type="submit"
                                        className="cstBtn"
                                    />
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </Grid2>
        </LoginUi>
    );
};

export default PasswordSetup;
