import * as Yup from 'yup';
export const changePasswordValidationSchema = (t: any) =>
    Yup.object({
        currentPassword: Yup.string()
            .required(t('current_password_required'))
            .min(8, t('password_min_length'))
            .max(20, t('password_max_length'))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,20}$)/,
                t('password_not_matched'),
            ),
        newPassword: Yup.string()
            .required(t('new_password_required'))
            .min(8, t('password_min_length'))
            .max(20, t('password_max_length'))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^\$\*\.\[\]\{\}\(\)\?\-"!@#%&\/\\,><':;|_~`+=])(?=.{8,20}$)/,
                t('password_not_matched'),
            )
            .test('not-same-as-current', t('new_password_same_as_current'), function (value) {
                const { currentPassword } = this.parent;
                return value !== currentPassword;
            }),
        confirmNewPassword: Yup.string()
            .required(t('confirm_password_required'))
            .oneOf([Yup.ref('newPassword')], t('password_not_matched_confirm')),
    });
