import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
    mutation UpdateUser($input: UpdateUser!) {
        updateUser(input: $input) {
            email
            first_name
            id
            last_name
            phone_number
            status
            profile_image
            user_addresses {
                address_type
                city
                country
                id
                state
                street
                user_id
                zip_code
            }
        }
    }
`;
